import { Button, Col, Form, Input, Row, Image } from 'antd'
import { LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import Logo from '../../assets/saha-logo-gradient.svg';
import { openNotification } from '../../utils';
import axios from 'axios';
import { MailOutlined } from '@ant-design/icons';

const Login = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const userGet = () => {
    UserService.getMe()
      .then((response: any) => {
        console.log(response)
        let role = response.data?.roles[0]?.name

        localStorage.setItem('firstName', response.data.firstName)
        localStorage.setItem('lastName', response.data.lastName)
        localStorage.setItem('email', response.data.email)
        localStorage.setItem('phoneNumber', response.data.phoneNumber)
        localStorage.setItem('profilePicture', response.data.profilePicture)
        localStorage.setItem('liveLessonCredit', response.data.liveLessonCredit)
        localStorage.setItem('role', response.data?.roles[0]?.name)
        localStorage.setItem('id', response.data?.id)
        localStorage.setItem('permissions', JSON.stringify(response.data?.roles[0]?.permissions))

        if (role === 'STUDENT') {
          navigate('/')
        }
        else {
          // openNotification('error', 'Başarısız', 'Giriş yapılamadı, öğrenci olduğunuzu onaylayın.')
          navigate('/') // Kaldırılacak
        }
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Giriş yapılamadı, Saha Eğitim öğrencisi değilsiniz.')
        setLoading(false);
        localStorage.clear();
        console.log(e)
      });
  }

  const updateMe = () => {
    let affiliate = localStorage.getItem('affiliate');
    console.log('affiliate', affiliate)
    if (affiliate != null) {
      let postObj = {
        "affiliate": affiliate,
      };
      UserService.updateMe(postObj)
        .then((response: any) => {
          localStorage.removeItem('affiliate');
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
    setLoading(false);
        });
    }
  };

  const onFinish = (values: { email: string; password: string }) => {

    setLoading(true);

    let userData = {
      "email": values.email,
      "password": values.password
    }

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/login';

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData
    };

    axios.request(config)
      .then((response: any) => {
        console.log(response)
        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);
        userGet();
        updateMe();
      })
      .catch((e: Error) => {
        console.log(e)
        openNotification('error', 'Başarısız', 'Giriş Yapılamadı.')
        setLoading(false);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form
        name="login_form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2>Giriş Yap</h2>
        <p>Hoşgeldiniz. Lütfen bilgilerinizi doldurun.</p>
        <p>E-Posta</p>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Lütfen E-posta giriniz!' }]}
        >
          <Input
            size='large'
            prefix={<MailOutlined />}
            className='form-input'
            placeholder="E-Posta"
          />
        </Form.Item>
        <p>Şifre</p>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
        >
          <Input.Password
            size='large'
            prefix={<LockOutlined />}
            placeholder="Şifre"
            iconRender={visible => (visible ? <EyeTwoTone onClick={togglePasswordVisibility} /> : <EyeInvisibleOutlined onClick={togglePasswordVisibility} />)}
          />
        </Form.Item>
        <Row align={'middle'} justify={'end'}>
          <Col>
            <Link to='/forgot-password'>Şifreni mi unuttun?</Link>
          </Col>
        </Row>
        <Form.Item>
          <Button htmlType="submit" loading={loading} size="large" className="w-full" type="primary">
            Giriş Yap
          </Button>
        </Form.Item>
      </Form>
      <Row justify={'center'}>
        <Col>
          <p>
            Hesabınız Yok mu? <Link to={'/kaydol'}>Kaydol</Link>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default Login