import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Card, Spin, Tooltip, Tag } from 'antd';
import { getTickets, deleteTicket } from './service/TicketService';
import NewTicketDrawer from './NewTicketDrawer';
import EditTicketDrawer from './EditTicketDrawer';
import TicketDetail from './TicketDetail';
import { Ticket } from './type/Ticket';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

const TicketList: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const data: Ticket[] = await getTickets();
      setTickets(data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicket(id);
      fetchTickets();
    } catch (error) {
      console.error('Failed to delete ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  const translatePriority = (priority: string) => {
    switch (priority) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    { title: '', dataIndex: 'id', key: 'id', render: (text: number) => <b>#{text}</b> },
    { title: 'Konu', dataIndex: 'subject', key: 'subject' },
    { title: 'Öncelik', dataIndex: 'priority', key: 'priority', render: (text: string) => translatePriority(text) },
    { title: 'Kayıt Tarihi', dataIndex: 'createdAt', key: 'createdAt', render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm') },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tooltip title="Düzenle">
            <Button icon={<EditOutlined />} onClick={() => { setSelectedTicket(record); setDrawerVisible(true); }} />
          </Tooltip>
          <Tooltip title="Sil">
            <Popconfirm
              title="Bu destek talebini silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDelete(record.id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Card title="Destek Talepleri" extra={<Button type="primary" onClick={() => { setSelectedTicket(null); setDrawerVisible(true); }}>Yeni Destek Talebi</Button>}>
      <Spin spinning={loading}>
        <div style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={tickets}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
        </div>      </Spin>
      {selectedTicket ? (
        <EditTicketDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          ticketId={selectedTicket.id}
          onSave={fetchTickets}
          initialValues={selectedTicket}
        />
      ) : (
        <NewTicketDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          onSave={fetchTickets}
          initialValues={{ priority: 'LOW' }}
        />
      )}
    </Card>
  );
};

export default TicketList;