import { Button, Card, Col, Collapse, Divider, Progress, Row, Space, Typography } from 'antd'
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { FilePdfOutlined, FileOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined } from '@ant-design/icons';
import CourseService from '../services/CourseService';
import { formatNumber, transformData } from '../utils';
import PaymentModal from '../components/Packages/PaymentModal';
import { ReactComponent as DocIcon } from '../assets/icons/course/doc.svg';
import { ReactComponent as ExplanationIcon } from '../assets/icons/course/explanation.svg';
import { ReactComponent as QuestionIcon } from '../assets/icons/course/question.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/course/video.svg';
import CourseCover from '../assets/course-cover.svg'
import LearnSidebar from '../components/Learn/LearnSidebar';
import PaymentService from '../services/PaymentService';
import React from 'react';
import VideoHistoryService from '../services/VideoHistoryService';
import { transformDataNew } from '../transformData';


const LearnDetail = () => {
    const { Title, Text } = Typography;
    const [loading, setLoading] = useState<boolean>();
    const [course, setCourse] = useState<any>();
    const [groups, setGroups] = useState<any>([])
    const [buyer, setBuyer] = useState<any>({});
    const { courseId } = useParams()
    const [totalVideo, setTotalVideo] = useState(0);
    const [totalDocument, setTotalDocument] = useState(0);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [totalSubject, setTotalSubject] = useState(0);
    const [checkoutFormContent, setCheckoutFormContent] = useState<string | null>(null);
    const [watchedVideoList, setWatchedVideoList] = useState<any[]>([]);

    useEffect(() => {
        getAllMeByCourse()
    }, [])

    const getAllMeByCourse = () => {
        setGroups([]);
        VideoHistoryService.getAllMeByCourse(courseId)
            .then((response: any) => {
                setWatchedVideoList(response.data);
                getPackageDetail()
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getPackageDetail = async () => {
        setLoading(true);
        CourseService.getDetail(courseId)
            .then((response: any) => {
                setGroups(response.data.groups);
                setCourse(response.data);
                setLoading(false);
                setTotalVideo(response.data.totalVideo);
                setTotalDocument(response.data.totalDocument);
                setTotalQuestion(response.data.totalQuestion);
                const allSubjects = response.data.groups.flatMap((group:any) => group.subjects);
                setTotalSubject(allSubjects.length);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    }

    const subjectRender = () => {
        return (
            <>
                {groups?.map((groupItem: any, index: number) => {
                    const transformedSubjects = transformDataNew(groupItem.subjects, watchedVideoList);
                    return (
                        <React.Fragment key={index}>
                            <Title level={3} style={{paddingLeft: 16, marginTop: 0, padding: 16, color: '#fff', background: '#935ac0'}}>{groupItem.name}</Title>
                            <LearnSidebar subjects={transformedSubjects}></LearnSidebar>
                        </React.Fragment>
                    );
                })}
            </>
        );  
    };

    return (
        <>
            {(course?.name) && <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={18}>
            <Row className='mb-16' justify={"space-between"} align={"middle"}>
                        <Col>
                            <Title className='m-0' level={4}>{course?.name}</Title>
                        </Col>
                        <Col>
                            <NavLink to={'/course'}>
                                <Button type="default" size='large'>
                                    Geri
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                    <Row gutter={[0, 12]}>
                        <Col span={24}>
                        <Card loading={loading} bodyStyle={{ padding: '10px 0px' }}>
                                {subjectRender()}
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <div style={{ position: 'sticky', top: '16px' }}>
                        <Card
                            loading={loading}
                            bodyStyle={{ padding: 16, minHeight: 90, paddingTop: 0 }}
                            className='bg-blur border-none'
                            cover={
                                <img
                                    src={CourseCover}
                                />
                            }
                        >
                            <Title level={4}>{course?.name}</Title>
                            <Row>
                                <Col>
                                    <div dangerouslySetInnerHTML={{ __html: course?.subhead }} />
                                </Col>
                            </Row>
                            {/* <Space direction="vertical">
                                <Row justify={"start"} align={"middle"}><ExplanationIcon className='mr-8' />{totalSubject} Konu Anlatımı</Row>
                                <Row justify={"start"} align={"middle"}><VideoIcon className='mr-8' />{totalVideo} Video</Row>
                                <Row justify={"start"} align={"middle"}><DocIcon className='mr-8' />{totalDocument} Doküman</Row>
                                <Row justify={"start"} align={"middle"}><QuestionIcon className='mr-8' />{totalQuestion} Soru</Row>
                            </Space> */}
                        </Card>
                    </div>
                </Col>
            </Row>
            }
        </>
    )
}

export default LearnDetail