import React, { useState } from 'react';
import { Modal, Input, Radio, Button, Select, Row, Col, Tag, Form } from 'antd';
import './PaymentModal.css'; // Make sure to create a CSS file for styles
import { formatNumber } from '../../utils';

const { Option } = Select;
const { useForm } = Form;

const PaymentModal: React.FC<any> = ({ packageItem, setBuyer }) => {
    const [invoiceType, setInvoiceType] = useState('personal');
    const [type, setType] = useState(true);
    const [form] = useForm();
    const [visible, setVisible] = useState(false);
    console.log(packageItem);

    const [allCities, setallCities] = useState(['Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
        'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
        'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
        'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
        'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya',
        'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
        'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
        'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
        'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce']);
    let authId = localStorage.getItem('id');

    const handleInvoiceTypeChange = (e: any) => {
        setInvoiceType(e.target.value);
        if (e.target.value === 'personel') {
            setType(true)
        } else {
            setType(false)
        }
    };


    const handlePaymentSubmit = (values: any) => {
        console.log(values);
        let postObj: any;


        if (type === true) {
            postObj = {
                "name": values.firstName,
                "surname": values.lastName,
                "identityNumber": values.idNumber,
                "billingAddress": values.billingAddress,
                "packageId": packageItem.id,
                "city": values.city,
                "email": values.email,
            };
        } else {
            postObj = {
                "name": values.firstName,
                "surname": values.lastName,
                "taxNumber": values.taxNumber,
                "taxOffice": values.taxOffice,
                "companyName": values.companyName,
                "billingAddress": values.billingAddress,
                "packageId": packageItem.id,
                "city": values.city,
                "email": values.email,
            };
        }

        setBuyer(postObj)
        handleClose()

    };

    const handleClose = () => {
        setVisible(false);
    };

    return (
        <>
            <Button className='w-full' type="primary" size="large" onClick={() => setVisible(true)}>
                Hemen Al
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                width={768} // Width based on the image
                className="payment-modal"
                footer={[]}
            >
                <Row style={{ marginBottom: 16 }}>
                    <Col>
                        <div style={{ border: '1px solid #000', height: 120, width: 120 }}></div>
                    </Col>
                    <Col>
                        <div style={{ marginLeft: 16, flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: 24, fontWeight: 700 }}>{packageItem.name} </span>
                                <span style={{ fontSize: 36, fontWeight: 700 }}>{formatNumber(packageItem.salePrice)} ₺</span>
                            </div>
                            {/* <Tag bordered={false} color="gold" style={{ marginTop: 8 }}>
                                Satın Alınan Paket
                            </Tag> */}
                        </div>
                    </Col>
                </Row>
                <Form form={form} layout="vertical" onFinish={handlePaymentSubmit}>

                    <span className='input-header'>Faturalandırma Seçenekleri</span>
                    <Radio.Group
                        defaultValue="personal"
                        onChange={handleInvoiceTypeChange}
                        value={invoiceType}
                        className="invoice-type-radio-group"

                    >
                        <Radio value="personal" className='invoice-radio-button' style={{ marginRight: 16 }}>Bireysel Fatura</Radio>
                        <Radio value="corporate" className='invoice-radio-button'>Kurumsal Fatura</Radio>
                    </Radio.Group>

                    {invoiceType === 'personal' && (
                        <div className="personal-invoice-form">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>Ad</span>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{ required: true, message: 'Lütfen adınızı giriniz' },
                                        { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Adınız" className='payment-input' />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Soyad</span>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{ required: true, message: 'Lütfen soyadınızı giriniz' },
                                        { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Soyadiniz" className='payment-input' />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>TC Kimlik</span>
                                    <Form.Item
                                        name="idNumber"
                                        rules={[{ required: true, message: 'Lütfen TC Kimlik numaranız giriniz' },
                                        { pattern: /^\d{11}$/, message: 'TC Kimlik numaranız 11 haneli ve rakam olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="TC Kimlik numaranız" className='payment-input' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Cep Telefonu</span>
                                    <Form.Item
                                        name="phoneNumber"
                                        rules={[
                                            { required: true, message: 'Lütfen telefon numaranızı giriniz' },
                                            { pattern: /^\d{10}$/, message: 'Telefon numarası 10 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Telefon Numarası" maxLength={11} className='payment-input' />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>E-Posta</span>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen E-postanızı giriniz'
                                            },
                                            {
                                                type: 'email',
                                                message: 'Lütfen geçerli bir E-posta adresi giriniz'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="E-posta" className='payment-input' />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Şehir</span>
                                    <Form.Item
                                        name="city"
                                        rules={[{ required: true, message: 'Lütfen İl seçiniz' }]}
                                    >
                                        <Select placeholder="Il" className='payment-select' style={{ width: '100%' }} >
                                            {allCities.map((cityName, key) => (
                                                <Option value={cityName.toLowerCase()}>{cityName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <span className='input-header'>Fatura Adresi</span>
                            <Form.Item
                                name="billingAddress"
                                rules={[{ required: true, message: 'Lütfen Faturalandırma adresinizi giriniz' }]}
                            >
                                <Input placeholder="Faturalandırma adresi" className='payment-input' />
                            </Form.Item>
                        </div>
                    )}

                    {invoiceType === 'corporate' && (
                        <div className="corporate-invoice-form">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>Ad</span>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{ required: true, message: 'Lütfen adınızı giriniz' },
                                        { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Adınız" className='payment-input' />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Soyad</span>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{ required: true, message: 'Lütfen soyadınızı giriniz' },
                                        { pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇı ]+$/, message: 'Lütfen sadece harf giriniz' }
                                        ]}
                                    >
                                        <Input placeholder="Soyadiniz" className='payment-input' />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>Vergi Kimlik Numarası</span>
                                    <Form.Item
                                        name="taxNumber"
                                        rules={[{ required: true, message: 'Lütfen Vergi Kimlik Numarası giriniz' },
                                        { pattern: /^\d{10,11}$/, message: 'Vergi Kimlik Numarası 10 veya 11 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Vergi Kimlik Numarası" className='payment-input' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Vergi Dairesi</span>
                                    <Form.Item
                                        name="taxOffice"
                                        rules={[{ required: true, message: 'Lütfen Vergi Dairesi giriniz' }]}
                                    >
                                        <Input placeholder="Vergi Dairesi" className='payment-input' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>E-posta</span>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Lütfen E-postanızı giriniz'
                                            },
                                            {
                                                type: 'email',
                                                message: 'Lütfen geçerli bir E-posta adresi giriniz'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="E-posta" className='payment-input' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>Telefon Numarası</span>
                                    <Form.Item
                                        name="phoneNumber"
                                        rules={[
                                            { required: true, message: 'Lütfen telefon numaranızı giriniz' },
                                            { pattern: /^\d{10}$/, message: 'Telefon numarası 10 haneli olmalıdır' }
                                        ]}
                                    >
                                        <Input placeholder="Telefon Numarası" maxLength={11} className='payment-input' />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <span className='input-header'>Firma Adı</span>
                                    <Form.Item
                                        name="companyName"
                                        rules={[{ required: true, message: 'Lütfen Firma Adı giriniz' }]}
                                    >
                                        <Input placeholder="Firma Adı" className='payment-input' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <span className='input-header'>İl</span>
                                    <Form.Item
                                        name="city"
                                        rules={[{ required: true, message: 'Lütfen İl giriniz' }]}
                                    >
                                        <Select placeholder="İl" className='payment-select' style={{ width: '100%' }} >
                                            {allCities.map((cityName, key) => (
                                                <Option value={cityName.toLowerCase()} >{cityName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <span className='input-header'>Fatura Adresi</span>
                            <Form.Item
                                name="billingAddress"
                                rules={[{ required: true, message: 'Lütfen Fatura Adresi giriniz' }]}
                            >
                                <Input placeholder="Fatura Adresi" className='payment-input' />
                            </Form.Item>
                        </div>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className='w-full'>
                            Ödemeye Geç
                        </Button>
                    </Form.Item>

                </Form>
            </Modal >
        </>
    );
};

export default PaymentModal;
