import React, { useEffect, useState } from 'react';
import { Drawer, Button, Spin, List, Typography, Divider, Popconfirm, Row, Col, Empty, Descriptions, Tag, Collapse } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getTicketDetails, deleteTicketAttachment } from './service/TicketService';
import { Ticket, TicketAttachment } from './type/Ticket';
import moment from 'moment';
import { formatDate, openNotification } from '../../utils';
import TicketDetail from './TicketDetail';
import FileUploadModal from './FileUploadModal';

const { Title } = Typography;
const { Panel } = Collapse;

interface EditTicketDrawerProps {
  visible: boolean;
  onClose: () => void;
  ticketId: number;
  onSave: () => void;
  initialValues: Partial<Ticket>;
}

const EditTicketDrawer: React.FC<EditTicketDrawerProps> = ({ visible, onClose, ticketId, onSave, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[]>('1');

  useEffect(() => {
    if (visible && ticketId) {
      fetchTicketDetails(ticketId);
    } else {
      setTicket(null);
    }
  }, [visible, ticketId]);

  const fetchTicketDetails = async (ticketId: number) => {
    setLoading(true);
    try {
      const ticketDetails = await getTicketDetails(ticketId);
      setTicket(ticketDetails);
    } catch (error) {
      openNotification('error', 'Hata', 'Destek talebi detayları alınırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAttachment = async (attachmentId: number) => {
    if (!ticket) return;
    setLoading(true);
    try {
      await deleteTicketAttachment(attachmentId);
      openNotification('success', 'Başarılı', 'Dosya başarıyla silindi');
      fetchTicketDetails(ticket.id); // Refresh the ticket details
    } catch (error) {
      openNotification('error', 'Hata', 'Dosya silinirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const getPriorityTag = (priority: string) => {
    let color;
    switch (priority) {
      case 'HIGH':
        color = 'red';
        break;
      case 'MEDIUM':
        color = 'orange';
        break;
      case 'LOW':
        color = 'green';
        break;
      default:
        color = 'blue';
    }
    return <Tag color={color}>{priority}</Tag>;
  };

  const handleUploadButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setUploadModalVisible(true);
  };

  return (
    <Drawer
      title={`Destek Talebi Detay`}
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Spin spinning={loading}>
        {ticket && (
          <Descriptions
            bordered
            column={1}
            labelStyle={{ fontWeight: 'bold', backgroundColor: '#f0f2f5' }} // Custom label style
          >
            <Descriptions.Item label="#" contentStyle={{ fontWeight: 'bold' }}>{ticket.id}</Descriptions.Item>
            <Descriptions.Item label="Konu">{ticket.subject}</Descriptions.Item>
            <Descriptions.Item label="Açıklama">{ticket.description}</Descriptions.Item>
            <Descriptions.Item label="Öncelik">{getPriorityTag(ticket.priority)}</Descriptions.Item>
            <Descriptions.Item label="Kayıt Tarihi">{formatDate(ticket.createdAt)}</Descriptions.Item>
          </Descriptions>
        )}
        <Collapse
          collapsible="icon"
          style={{ marginTop: 16 }}
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          expandIconPosition="right"
        >
          <Panel header={
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title style={{ padding: 0, margin: 0 }} level={5}>Dosyalar</Title>
              </Col>
              <Col>
                <Button type="primary" style={{marginRight: 8}} onClick={handleUploadButtonClick}>
                  Dosya Yükle
                </Button>
              </Col>
            </Row>
          } key="1">
            {ticket && ticket.attachments.length > 0 ? (
              <List
                style={{ padding: "0px 16px" }}
                dataSource={ticket.attachments}
                renderItem={(attachment: TicketAttachment) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Bu dosyayı silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDeleteAttachment(attachment.id)}
                        okText="Evet"
                        cancelText="Hayır"
                      >
                        <Button icon={<DeleteOutlined />} danger />
                      </Popconfirm>,
                    ]}
                  >
                    <a href={attachment.fileUrl} target="_blank" rel="noopener noreferrer">
                      {attachment.filePath.split('/').pop()}
                    </a>
                  </List.Item>
                )}
              />
            ) : (
              <Empty style={{margin: 24}} description="Dosya mevcut değil" />
            )}
          </Panel>
        </Collapse>

        <Divider />
        <TicketDetail ticketId={ticket?.id || 0} onClose={onSave} />
      </Spin>
      <FileUploadModal
        visible={uploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
        ticketId={ticket?.id || 0}
        onUploadSuccess={() => fetchTicketDetails(ticketId)}
      />
    </Drawer>
  );
};

export default EditTicketDrawer;