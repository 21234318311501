import { Card, Drawer, Divider, Tag, Switch, Popconfirm, Button, DatePicker, Form, Row, Tooltip, Avatar, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { openNotification } from '../../utils';
import LiveLessonService from './../../services/P2PService';
import './lesson-detail.css';
import moment from 'moment';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';

const LiveLessonDetail: React.FC<any> = ({ item, getLiveLessons }) => {
    const [status, setStatus] = useState(item.status);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lesson, setLesson] = useState<any>({});
    const authId = localStorage.getItem('id');
    const [liveLessonDate, setLiveLessonDate] = useState(dayjs(item.item.liveLessonDate));
    //MODAL SETTINGS
    const showModal = () => {
        setLoading(true);
        setIsModalOpen(true);
        getLesson(item.id)
    };

    const getLesson = (id: number) => {
        LiveLessonService.get(id)
            .then((response: any) => {
                let data = response.data
                setLesson(data)
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e)
            });
    }

    const handeLearnerList = () => {
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const confirmDeleteLesson = () => {
        LiveLessonService.remove(item.id)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ders Silindi');
                getLiveLessons();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };

    const handleLiveLessonDateChange = (value: any) => {
        if (value) {
            const formattedDate = value.format("YYYY-MM-DDTHH:mm");
            LiveLessonService.update(item.id, { liveLessonDate: formattedDate })
                .then(() => {
                    openNotification('success', 'Başarılı', 'Tarih başarıyla güncellendi.');
                    setLiveLessonDate(value);
                    getLesson(item.id)
                    getLiveLessons();
                })
                .catch((e) => {
                    console.error(e);
                    openNotification('error', 'Error', 'An error occurred while updating the due date.');
                });
        }
    };

    const onChange = (checked: boolean) => {
        let authIdInt: any;

        if (checked) {
            setStatus('Onaylandı');
            authIdInt = authId !== null ? parseInt(authId, 10) : null;
        } else {
            setStatus('Onaylanmadı');
            authIdInt = null;
        }

        LiveLessonService.update(item.id, { isApprovel: checked, userId: checked ? authIdInt : null })
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Dersi onaylandı.');
                getLesson(item.id)
                getLiveLessons();
            })
            .catch((e: Error) => {
                console.log(e)
            });
    };


    const disabledDate = (current: any) => {
        return current && current < moment().startOf('day');
    };

    const disabledDateTime = () => {
        const currentHour = moment().hour();
        const currentMinutes = moment().minutes();
        let disabledHours: any = [];
        for (let i = 0; i < currentHour; i++) {
            disabledHours.push(i);
        }

        let disabledMinutes: any = [];
        if (currentHour === moment().hour()) {
            for (let i = 0; i < currentMinutes; i++) {
                disabledMinutes.push(i);
            }
        }

        return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
        };
    };

    const getColor = (item: any) => {
        if (item.isComplete) return 'green';
        if (item.userId === authId) return 'blue';
        if (item.userId == null) return 'orange';
        return 'yellow';
    };
    return (
        <>
            <Tooltip title={item.item.note ?? ''} placement="top">
                <Tag
                    style={{ cursor: 'pointer', padding: 5, marginBottom: 10 }}
                    onClick={showModal}
                    color={getColor(item.item)}>

                    {item.item?.lessonName}
                </Tag>
            </Tooltip>

            {/* <Button className='mr-15 lesson-detail' key={item?.id} onClick={showModal} type={item.status == 'DONE' ? 'default' : 'primary'} size="small">{item?.title}</Button> */}
            <Drawer title="Canlı Ders Detay" placement="right" onClose={handleCancel} open={isModalOpen} style={{ padding: '0px', margin: '0px', border: 'none' }}>
                <Card loading={loading} className='lesson-detail-card' style={{ padding: '0px', margin: '0px', border: 'none' }}>
                    <h4>Ders</h4>
                    <p>{item?.item?.lessonName}</p>
                    <Divider />
                    <h4>Not</h4>
                    <p>{lesson?.note}</p>
                    <Divider />
                    <h4>Ders Tarihi</h4>
                    <Form.Item
                        name="liveLessonDate"
                        rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                        <DatePicker
                            showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
                            style={{ width: "100%" }}
                            format="DD/MM/YYYY HH:mm"
                            placeholder="Select Date"
                            defaultValue={liveLessonDate}
                            onChange={handleLiveLessonDateChange}
                            locale={locale}
                            disabledDate={disabledDate}
                            disabledTime={disabledDateTime}
                        />
                    </Form.Item>
                    {
                        item?.item?.isComplete ?
                            <>
                                <Divider />
                                <h4>Tamamlanma Tarihi</h4>
                                <Form.Item >
                                    <DatePicker
                                        showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
                                        style={{ width: "100%" }}
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="Select Date"
                                        defaultValue={item?.item?.completeDate ? dayjs(item.item.completeDate) : null}
                                        locale={locale}
                                        disabled
                                        disabledTime={disabledDateTime}
                                    />
                                </Form.Item>
                            </>
                            : null
                    }
                    <Divider />
                    <Row justify={"space-between"} align={"middle"}>
                        <Col>
                            <Switch disabled checkedChildren={<b>Onaylandı</b>} unCheckedChildren={<b>Onaylanmadı</b>} onChange={onChange} checked={lesson.isApprovel} />
                        </Col>
                        <Col>
                            <a href={lesson?.link} target="_blank" rel="noopener noreferrer">
                                <Button type='primary'>
                                    Zooma Bağlan
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Drawer >
        </>
    );
}
export default LiveLessonDetail;
