import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Spin } from 'antd';
import UserService from '../services/UserService';
import { openNotification } from '../utils';

const LoginByToken = () => {
    const { accessToken } = useParams<{ accessToken: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            userGet();
            updateMe();
        }
    }, [accessToken]);

    const userGet = () => {
        UserService.getMe()
            .then((response: any) => {
                console.log(response);
                let role = response.data?.roles[0]?.name;

                localStorage.setItem('firstName', response.data.firstName);
                localStorage.setItem('lastName', response.data.lastName);
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('phoneNumber', response.data.phoneNumber);
                localStorage.setItem('role', response.data?.roles[0]?.name);
                localStorage.setItem('id', response.data?.id);
                localStorage.setItem('permissions', JSON.stringify(response.data?.roles[0]?.permissions));

                if (role === 'STUDENT') {
                    navigate('/');
                } else {
                    navigate('/'); // Adjust as needed
                }
            })
            .catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Giriş yapılamadı, Saha Eğitim öğrencisi değilsiniz.');
                localStorage.clear();

                console.log(e);
            });
    };

    const updateMe = () => {
        let affiliate = localStorage.getItem('affiliate');
        console.log('affiliate', affiliate);
        if (affiliate != null) {
            let postObj = {
                affiliate: affiliate,
            };
            UserService.updateMe(postObj)
                .then((response: any) => {
                    localStorage.removeItem('affiliate');
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    const handleLoginClick = async () => {
        setLoading(true);
        try {
            const token = await navigator.clipboard.readText();
            if (!token) {
                openNotification('error', 'Başarısız', 'Panoda geçerli bir token bulunamadı.');
                setLoading(false);
                return;
            }

            // Simulate setting the token and calling userGet
            localStorage.setItem('accessToken', token);
            userGet(); // Attempt to login with the token

        } catch (error) {
            openNotification('error', 'Başarısız', 'Panodan token alınamadı.');
            console.log('Failed to read token from clipboard', error);
            setLoading(false);
        }
    };

    return (
        <Row justify={"center"} align={"middle"} style={{height: "100vh"}}>
            <Button type="primary" size="large" onClick={handleLoginClick} disabled={loading}>
                {loading ? <Spin /> : 'Giriş Yap'}
            </Button>
        </Row>
    );
};

export default LoginByToken;
