import { Calendar, Button, Modal, Form, Input, Select, DatePicker, Row, Col, ConfigProvider, Card } from 'antd';
import { useState, useEffect } from 'react';
import P2PService from '../services/P2PService';
import LessonService from '../services/LessonService';
import moment from 'moment';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import LiveLessonDetail from '../components/P2PLesson/LiveLessonDetail';
import '../components/P2PLesson/lesson-detail.css';
import { openNotification } from '../utils';
import trTR from 'antd/lib/locale/tr_TR';
import locale from 'antd/es/date-picker/locale/tr_TR';
import Filter from '../components/P2PLesson/Filter';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { NavLink } from 'react-router-dom';
import UserService from '../services/UserService';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const P2PLesson = () => {
  const [lessons, setLessons] = useState([]);
  const [liveLessonCredit, setLiveLessonCredit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allLessons, setAllLessons] = useState<any>([]);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [filter, setFilter] = useState<any>({
    startLiveLessonDate: dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endLiveLessonDate: dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
  });

  let authId = localStorage.getItem('id');

  const getLiveLessons = async () => {
    try {
      const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
      setLoading(true)
      const [liveLessonsResponse, lessonsResponse] = await Promise.all([
        P2PService.getAll(`?${queryString}`),
        LessonService.getAll()
      ]);

      const lessonsMap = new Map<string, string>();
      (lessonsResponse?.data as any).forEach((lesson: any) => {
        lessonsMap.set(lesson.id, lesson.name);
      });

      const lessonsData = (liveLessonsResponse?.data as any).map((liveLesson: any) => {
        const lessonName = lessonsMap.get(liveLesson.lessonId);
        return {
          ...liveLesson,
          lessonName,
          liveLessonMoment: moment(liveLesson.liveLessonDate)
        };
      });
      setAllLessons(lessonsResponse?.data)
      setLessons(lessonsData);
      setLoading(false)

      // setLessons(lessonsData);
    } catch (error) {
      console.error(error);
      setLoading(false)

    }
  };

  useEffect(() => {
    var now = dayjs()
    onPanelChange(now);
    getMe();
  }, []);

  const getListData = (x: Dayjs) => {
    const dateData: any = lessons || [];
    console.log(dateData);
    let matchedItems = [];
    for (let item of dateData) {
      if (x.format('YYYY-MM-DD') === dayjs(item.liveLessonDate).format('YYYY-MM-DD')) {
        matchedItems.push({ type: 'success', item: item, id: item.id });
      }
    }

    return matchedItems.length > 0 ? matchedItems : [];
  };

  const dateCellRender = (currentDate: any) => {
    const listData = getListData(currentDate);
    return (
      <ul className="events" style={{
        listStyleType: 'none',
        paddingLeft: 5
      }}>
        {listData.map((lesson: any, index) => (
          <li key={index} >
            {lesson.item && (
              <LiveLessonDetail item={lesson} getLiveLessons={getLiveLessons} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  const showRequestModal = () => {
    setIsRequestModalOpen(true);
  };

  const handleRequestModalCancel = () => {
    setIsRequestModalOpen(false);
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };

  const handleRequestSubmit = async (values: any) => {
    const localDate = dayjs(values.liveLessonDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');

    let postObj: any = {
      "link": "https://sahadabasari.com/",
      "note": values.note,
      "isApprovel": false,
      "liveLessonDate": localDate,
      "lessonId": values.lessonId
    };

    P2PService.create(postObj)
      .then((response: any) => {
        form.resetFields();
        openNotification('success', 'Başarılı', 'Ders talebi Oluşturuldu.');
        getLiveLessons();
        getMe();
      }).catch((e: Error) => {
        console.log(e)
      });

    setIsRequestModalOpen(false);
  };


  const handleOnChangeFilter = (values: any) => {
    setFilter((prev: any) => {
      const newFilter = { ...prev };

      if (values.student !== undefined) {
        newFilter.studentId = values.student;
      } else {
        delete newFilter.studentId; // Eğer undefined ise, filter objesinden bu anahtarı çıkarıyoruz.
      }

      if (values.teacher !== undefined) {
        newFilter.userId = values.teacher;
      } else {
        delete newFilter.userId;
      }

      if (values.lesson !== undefined) {
        newFilter.lessonId = values.lesson;
      } else {
        delete newFilter.lessonId;
      }

      if (values.isApprovel !== undefined) {
        newFilter.isApprovel = values.isApprovel;
      } else {
        delete newFilter.isApprovel;
      }

      if (values.isComplete !== undefined) {
        newFilter.isComplete = values.isComplete;
      } else {
        delete newFilter.isComplete;
      }

      return newFilter;
    });
  };

  const getMe = () => {
    UserService.getMe()
      .then((response: any) => {
        localStorage.setItem('liveLessonCredit', response.data.liveLessonCredit)
        setLiveLessonCredit(response.data.liveLessonCredit);
      }).catch((e: Error) => {

      });
  }

  const onPanelChange = (value: Dayjs) => {
    const startOfMonth = value.startOf('month').format('YYYY-MM-DDT00:00:00');
    const endOfMonth = value.endOf('month').format('YYYY-MM-DDT23:59:59');

    setFilter((prev: any) => ({
      ...prev,
      startLiveLessonDate: startOfMonth,
      endLiveLessonDate: endOfMonth,
    }));
  };


  useEffect(() => {
    getLiveLessons();
  }, [filter]);

  return (
    <>
      <ConfigProvider locale={trTR}>
        <Card bodyStyle={{ paddingTop: '0px' }} title="Canlı Dersler"
          extra={<Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
            <Col>
              <b>Canlı Ders Hakkı:</b> {liveLessonCredit}
            </Col>
            <Col>
              <Button type="primary" disabled={liveLessonCredit == 0} size="large" onClick={showRequestModal}>
                Canlı Ders Talep Et
              </Button>
            </Col>
            <Col>
              <Filter onChangeFilter={handleOnChangeFilter} lessons={allLessons} />
            </Col>
            <Col>
              <NavLink to="/" >
                <Button size="large">
                  Geri
                </Button>
              </NavLink>
            </Col>
          </Row>}>
          <Calendar cellRender={dateCellRender} mode="month" onPanelChange={onPanelChange} />
        </Card>
        <Modal
          title="Canlı Ders Talep Et"
          visible={isRequestModalOpen}
          onCancel={handleRequestModalCancel}
          footer={null}
        >
          <Form form={form} layout="vertical" onFinish={handleRequestSubmit}>
            <Form.Item
              name="lessonId"
              label="Ders Adı"
              rules={[{ required: true, message: 'Lütfen ders seçiniz' }]}
            >
              <Select placeholder="Ders" style={{ width: '100%' }} >
                {allLessons.map((lesson: any) => (
                  <Option value={lesson.id} >{lesson.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="note"
              label="Not"
            >
              <Input placeholder='Not' />
            </Form.Item>
            <Form.Item
              name="liveLessonDate"
              label="Ders Tarihi ve Saati"
              rules={[{ required: true, message: 'Lütfen bir tarih seç!' }]}
            >

              <DatePicker
                style={{ width: "100%" }}
                format="DD/MM/YYYY HH:mm"
                placeholder="Tarih Seçiniz"
                locale={locale}
                showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Talebi Gönder
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default P2PLesson;
