import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import './App.css';
import './style/main.scss';
import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import Login from './views/Auth/Login';
import Register from './views/Auth/Register';
import Packages from './views/Packages';
import P2PLesson from './views/P2PLesson';
import Questions from './views/StudentQuestions';
import Exams from './views/Exams';
import Support from './views/Support';
import VideoPage from './views/VideoPage';
import Explanation from './views/Explanation';
import Document from './views/Document';
import Question from './views/Questions';
import PackageDetail from './components/Packages/PackageDetail';
import Quizzes from './views/Quizzes';
import Quiz from './views/Quiz';
import Application from './views/Auth/Application';
import PaymentCallbackError from './components/Packages/PaymentCallbackError';
import PaymentCallbackSuccess from './components/Packages/PaymentCallbackSuccess';
import QuizDetail from './views/QuizDetail';
import CourseList from './views/CourseList';
import Learn from './views/Learn';
import Courses from './components/Course/Courses';
import QuizResultPage from './views/QuizResult';
import LoginByToken from './views/LoginByToken';
import { ConfigProvider } from 'antd';
import LearnDetail from './views/LearnDetail';
import CourseDetail from './views/CourseDetail';
import ReminderPage from './views/ReminderPage';

import trTR from 'antd/locale/tr_TR';
import ForgotPassword from './views/Auth/ForgotPassword';
import ForgotToNewPassword from './views/Auth/ForgotToNewPassword';
import EmailVerifySend from './views/Auth/EmailVerifySend';
import EmailVerification from './views/EmailVerification';
import TicketList from './modules/Ticket/TicketList';

const commonToken = {
  fontFamily: '"Open Sans", sans-serif',
  borderRadius: 8,
  fontSize: 14,
  fontSizeLg: 18,
  fontSizeSm: 10,
};

const lightTheme = {
  token: {
    ...commonToken,
    colorPrimary: '#935ac0', // Deep Koamaru
    colorSecondary: '#B0E54F', // Inchworm
    colorText: '#03020A', // Rich Black
    colorBgContainer: '#ffffff', // White background
    colorBorder: '#d9d9d9',
    colorBgLayout: '#f0f2f5', // Light gray background
    colorButtonText: '#ffffff', // White button text color for light mode
    colorSwitchBg: '#d9d9d9', // Switch background in light mode
    colorSwitchHandle: '#ffffff', // Switch handle color in light mode
  },
};

function App() {

  return (
    <ConfigProvider theme={lightTheme} locale={trTR}>
      <div className="App">
        <Router>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/course" element={<CourseList />} />
              <Route path="/my-course" element={<Courses />} />
              {/* <Route path="/packages" element={<Packages />} /> */}
              <Route path="/payment-error" element={<PaymentCallbackError />} />
              <Route path="/payment-success" element={<PaymentCallbackSuccess />} />
              <Route path="/course/:id" element={<CourseDetail />} />
              <Route path="/course/:courseId/detail" element={<LearnDetail />} />
              {/* <Route path="/package/:id/detail" element={<PackageDetail />} /> */}
              {/* <Route path="/reminder" element={<ReminderPage />} /> */}
              <Route path="/reminder" element={<P2PLesson />} />
              <Route path="/live-lesson" element={<P2PLesson />} />
              <Route path="/student-questions" element={<Questions />} />
              <Route path="/exams" element={<Exams />} />
              <Route path="/support" element={<TicketList />} />
              {/* <Route path="/video/:id/subject/:subjectId/package/:packageId" element={<VideoPage />} /> */}
              <Route path="/video/:id/subject/:subjectId/course/:courseId/unit/:unitId/lesson/:lessonId" element={<Learn />} />
              <Route path="/explanation/:id" element={<Explanation />} />
              <Route path="/document/:id" element={<Document />} />
              <Route path="/questions/:id" element={<Question />} />
              <Route path="/quizzes" element={<Quizzes />} />
              <Route path="/quiz/:id" element={<QuizDetail />} />
              <Route path="/quiz/:id/result" element={<QuizResultPage />} />
              {/* <Route path="/quiz/old/:id" element={<Quiz />} /> */}
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/giris" element={<Login />} />
              <Route path="/kaydol" element={<Register />} />
              <Route path="/basvuru" element={<Application />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/email-verify-check" element={<EmailVerifySend />} />
              <Route path="/forgot-password-confirm" element={<ForgotToNewPassword />} />
            </Route>
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route path="/login-by-token" element={<LoginByToken />} />
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
