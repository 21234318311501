import React, { useEffect, useState } from 'react';
import { Button, Input, Spin, Table, message, Popconfirm, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { TicketResponse } from './type/Ticket';
import { getTicketResponses, createTicketResponse, updateTicketResponse, deleteTicketResponse } from './service/TicketService';

interface TicketDetailProps {
  ticketId: number;
  onClose: () => void;
}

const TicketDetail: React.FC<TicketDetailProps> = ({ ticketId, onClose }) => {
  const [responses, setResponses] = useState<TicketResponse[]>([]);
  const [currentResponse, setCurrentResponse] = useState<TicketResponse | null>(null);
  const [responseText, setResponseText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResponses(ticketId);
  }, [ticketId]);

  const fetchResponses = async (ticketId: number) => {
    setLoading(true);
    try {
      const responses = await getTicketResponses(ticketId);
      setResponses(responses);
    } finally {
      setLoading(false);
    }
  };

  const handleAddResponse = async () => {
    if (!responseText.trim()) {
      message.error('Yanıt metni boş olamaz');
      return;
    }

    const newResponse: any = {
      responseText,
      ticketId,
    };
    setLoading(true);
    try {
      await createTicketResponse(ticketId, newResponse);
      setResponseText('');
      await fetchResponses(ticketId); // Refresh the list
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateResponse = async () => {
    if (!currentResponse) return;
    if (!responseText.trim()) {
      message.error('Yanıt metni boş olamaz');
      return;
    }

    const updatedResponse = { ...currentResponse, responseText, ticketId }; // Include ticketId
    setLoading(true);
    try {
      await updateTicketResponse(ticketId, updatedResponse);
      setCurrentResponse(null);
      setResponseText('');
      await fetchResponses(ticketId); // Refresh the list
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteResponse = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicketResponse(id);
      await fetchResponses(ticketId); // Refresh the list
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Yanıt Metni',
      dataIndex: 'responseText',
      key: 'responseText',
    },
    {
      title: 'Ad Soyad',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Oluşturulma Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_: any, record: TicketResponse) => (
        <Popconfirm
          title="Bu yanıtı silmek istediğinize emin misiniz?"
          onConfirm={() => handleDeleteResponse(record.id)}
          okText="Evet"
          cancelText="Hayır"
        >
          <Button icon={<DeleteOutlined />} danger />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row justify={"space-between"} gutter={[16,16]} style={{margin: "16px 0px"}}>
        <Col span={18}>
          <Input
            value={responseText}
            onChange={(e) => setResponseText(e.target.value)}
            placeholder="Yanıt metnini girin"
          />
        </Col>
        <Col span={6}>
          {currentResponse ? (
            <Button icon={<EditOutlined />} onClick={handleUpdateResponse}>Yanıtı Güncelle</Button>
          ) : (
            <Button icon={<PlusOutlined />} onClick={handleAddResponse}>Yanıt Ekle</Button>
          )}
        </Col>
      </Row>
      <Table
        dataSource={responses}
        columns={columns}
        rowKey="id"
      />
    </Spin>
  );
};

export default TicketDetail;