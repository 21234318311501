import { Col, Row, Image } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import Logo from './assets/saha-logo-gradient.svg';
import Cover from './assets/cover.png';

const AuthLayout = () => {
  return (
    <>
      <Row className='auth-layout'>
        <Col md={12} xs={0}>
          <Row style={{ height: '100vh', position: 'sticky', top: '0px' }} className="bg-gradient" justify={"center"} align={"bottom"}>
            <Image width={"100%"} preview={false} src={Cover}></Image>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Row style={{ height: '100vh', maxHeight: '100vh', overflow: 'auto' }} className='p-16' justify={"center"} align={"middle"}>
            <Col xxl={14} lg={18} md={20} sm={24} xs={24}>
              <Row justify={'center'}>
                <Col>
                <a href='https://sahadabasari.com/'>
                  <Image width={200} preview={false} src={Logo}></Image>
                </a>
                </Col>
              </Row>
              <Outlet />
              <Row justify={'center'}>
                <Col>
                  <p>
                    Bizimle çalışmak ister misiniz? <Link to={'/basvuru'}>Başvur</Link>
                  </p>
                </Col>
                <Col>
                  <p>© 2024 Tüm Hakları Saklıdır.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AuthLayout