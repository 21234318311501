import { http } from "../../../http-common";
import { Ticket, TicketCreateUpdate, TicketResponse, TicketResponseCreateUpdate } from "../type/Ticket";

export const getTickets = async (): Promise<Ticket[]> => {
  const response = await http.get<Ticket[]>('/accounts/me/tickets');
  return response.data;
};

export const getTicketDetails = async (id: number): Promise<Ticket> => {
  const response = await http.get<Ticket>(`/tickets/${id}`);
  return response.data;
};

export const createTicket = async (ticket: TicketCreateUpdate): Promise<number> => {
  const response = await http.post<number>('/accounts/me/tickets', ticket);
  return response.data;
};

export const updateTicket = async (id: number, ticket: TicketCreateUpdate): Promise<Ticket> => {
  const response = await http.patch<Ticket>(`/tickets/${id}`, ticket);
  return response.data;
};

export const deleteTicket = async (id: number): Promise<void> => {
  await http.delete<void>(`/tickets/${id}`);
};

// Ticket Response Services
export const getTicketResponses = async (ticketId: number): Promise<TicketResponse[]> => {
  const response = await http.get<TicketResponse[]>(`/accounts/me/tickets/${ticketId}/ticket-responses`);
  return response.data;
};

export const createTicketResponse = async (ticketId: number, response: TicketResponseCreateUpdate): Promise<TicketResponse> => {
  const httpResponse = await http.post<TicketResponse>(`/accounts/me/tickets/${ticketId}/ticket-responses`, response);
  return httpResponse.data;
};

export const updateTicketResponse = async (id: number, response: TicketResponseCreateUpdate): Promise<TicketResponse> => {
  const httpResponse = await http.put<TicketResponse>(`/ticket-responses/${id}`, response);
  return httpResponse.data;
};

export const deleteTicketResponse = async (id: number): Promise<void> => {
  await http.delete<void>(`/ticket-responses/${id}`);
};

export const uploadTicketAttachment = async (ticketId: number, file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  await http.post(`/tickets/${ticketId}/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteTicketAttachment = async (id: number): Promise<void> => {
  await http.delete<void>(`/ticket-attachments/${id}`);
};