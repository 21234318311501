import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Select, Spin, Upload, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { createTicket, uploadTicketAttachment } from './service/TicketService';
import { TicketCreateUpdate } from './type/Ticket';
import { openNotification } from '../../utils';

const { Option } = Select;
const { Title } = Typography;

interface NewTicketDrawerProps {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  initialValues: Partial<TicketCreateUpdate>;
}

const NewTicketDrawer: React.FC<NewTicketDrawerProps> = ({ visible, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const onFinish = async (values: TicketCreateUpdate) => {
    setLoading(true);
    try {
      const newTicketId = await createTicket(values);
      if (fileList.length > 0) {
        await uploadTicketAttachment(newTicketId, fileList[0].originFileObj);
      }
      openNotification('success', 'Başarılı', 'Destek talebi başarıyla oluşturuldu');
      onSave();
      onClose();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      openNotification('error', 'Hata', 'Destek talebi oluşturulurken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = ({ fileList }: any) => {
    setFileList(fileList);
  };

  return (
    <Drawer
      title="Destek Talebi Oluştur"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
          <Form.Item name="subject" label="Konu" rules={[{ required: true, message: 'Lütfen konuyu girin' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Açıklama" rules={[{ required: true, message: 'Lütfen açıklamayı girin' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="priority" label="Öncelik" rules={[{ required: true, message: 'Lütfen önceliği seçin' }]}>
            <Select>
              <Option value="LOW">Düşük</Option>
              <Option value="MEDIUM">Orta</Option>
              <Option value="HIGH">Yüksek</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Dosya Ekle">
            <Upload
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleUploadChange}
            >
              <Button icon={<UploadOutlined />}>Dosya Yükle</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Oluştur
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default NewTicketDrawer;