import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import DocumentService from '../services/DocumentService';
import { Button, Card, Col, Row } from 'antd';
import { IDocumentResponse } from '../types/Documents';
import { PDFDocument, rgb, degrees } from 'pdf-lib';
import { turkishToEnglish } from '../utils';

const Document = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [document, setDocument] = useState<IDocumentResponse>();
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const firstName: any = localStorage.getItem('firstName');
    const lastName: any = localStorage.getItem('lastName');
    const phoneNumber: any = localStorage.getItem('phoneNumber');
    const navigate = useNavigate()

    const { id } = useParams()

    useEffect(() => {
        getService()
    }, [])


    const getService = () => {
        setLoading(true)
        DocumentService.get(id)
            .then((response: any) => {
                const data = response.data
                setDocument(data)
                getFile(data?.url);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false)
            });
    };

    const getFile = (fileName: any) => {
        DocumentService.getFile(fileName)
            .then(async (response: any) => {
                try {
                    const pdfBlob = response.data; // Blob olarak alınan PDF
                    const arrayBuffer = await pdfBlob.arrayBuffer(); // Blob'dan ArrayBuffer'a çevirme

                    // PDF dosyasını yükle
                    const pdfDoc = await PDFDocument.load(arrayBuffer);

                    // Her sayfaya filigran ekleme
                    const pages = pdfDoc.getPages();
                    pages.forEach(page => {

                        let firstNameText = turkishToEnglish(firstName);
                        let lastNameText = turkishToEnglish(lastName);

                        const { width, height } = page.getSize();
                        const fontSize = 25; // Metin boyutunu küçült
                        const text = `${firstNameText} ${lastNameText}\n${phoneNumber}`;
                        const textWidth = fontSize * text.length * 0.6; // Yaklaşık metin genişliği

                        // Metinleri dikey olarak yerleştirecek aralıklar
                        const yOffsetIncrement = height / 5;

                        // Sol yarıda dört metin
                        for (let i = 1; i <= 4; i++) {
                            page.drawText(text, {
                                x: width / 3 - textWidth / 2,
                                y: yOffsetIncrement * i,
                                size: fontSize,
                                color: rgb(0.75, 0.75, 0.75),
                                rotate: degrees(40), // 45 derece açı
                                opacity: 0.7
                            });
                        }

                        // Sağ yarıda dört metin
                        for (let i = 1; i <= 4; i++) {
                            page.drawText(text, {
                                x: 2 * width / 2 - textWidth / 2,
                                y: yOffsetIncrement * i,
                                size: fontSize,
                                color: rgb(0.75, 0.75, 0.75),
                                rotate: degrees(40), // 45 derece açı
                                opacity: 0.7
                            });
                        }
                    });

                    // Değişikliklerle yeni bir PDF dosyası oluştur
                    const newPdfBytes = await pdfDoc.save();

                    // Yeni PDF için Blob oluştur ve URL'yi ayarla
                    const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
                    const url = URL.createObjectURL(newPdfBlob);
                    setPdfUrl(url);
                    setLoading(false)
                } catch (e) {
                    console.error('PDF işleme sırasında hata oluştu:', e);
                }
            }).catch((e: Error) => {
                setLoading(false)
                console.error('PDF dosyasını alırken hata oluştu:', e);
            });
    }


    return (
        <>
            <Row justify={"space-between"}>
                <Col>
                    <h3>{document?.title}</h3>
                </Col>
                <Col>
                    <Button onClick={() => navigate(-1)} className='fw-700'>
                        Geri
                    </Button>
                </Col>
            </Row>
            <Card loading={loading}>
                {pdfUrl && (
                    <iframe
                        title="Document"
                        src={pdfUrl}
                        width="100%"
                        height="900"
                    />
                )}
            </Card>
        </>
    )
}

export default Document